<script>

import Layout from "@layouts/main.vue";


export default {
  name: 'index',
  components: {Layout},
  data() {
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.user.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    }

    return {
      user: {},
      rules: {
        netname: [
          {required: true, message: '请输入用户昵称', trigger: 'blur'}
        ],
        phoneCode: [
          {required: true, message: '请输入短信验证码', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min: 8, message: '密码长度不符合最短8位', trigger: 'blur'}
        ],
        passwordConfirm: [
          {required: true, validator: validatePass2, trigger: 'blur'}
        ]
      },
    }
  },
  methods: {
    getInfo() {
      this.$axios.get('/User/My').then((res) => {
        this.user = res.data.data
      })
    },
    update() {
      this.$axios.post('/User/Update', this.user).then((res) => {
        this.$message({
          message: res.data.msg,
          type: res.data.code === 1001 ? 'success' : 'warning'
        });
      })
    }
  },
  created() {
    this.getInfo()
  }
}

</script>

<template>
  <Layout>
    <div class="container mt-5 mb-5">
      <div class="row">
        <div class="col-md-5 mx-auto">
          <h2 class="site-title text-center mb-5">个人中心</h2>
          <el-form ref="ruleForm" label-position="top">

            <el-form-item label="用户名">
              <el-input disabled v-model="user.username"></el-input>
            </el-form-item>

            <el-form-item label="昵称">
              <el-input v-model="user.netname"></el-input>
            </el-form-item>

            <el-form-item label="性别">
              <el-radio-group v-model="user.gender">
                <el-radio label="1">男</el-radio>
                <el-radio label="0">女</el-radio>
              </el-radio-group>
            </el-form-item>


            <el-form-item label="重置密码">
              <el-input v-model="user.password" placeholder="留空则不重置密码"></el-input>
            </el-form-item>

            <el-form-item class="text-center">
              <el-button type="primary" @click="update">保存信息</el-button>
            </el-form-item>

          </el-form>

        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
@import "/src/assets/css/element-variables.scss";


</style>